@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --color: #2b2b2b;
  --color2: #3D3D3D;
  --color3: #CCCCCC;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fefefe;
  font-family: "Poppins", sans-serif;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Navbar */

nav {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #FFFFFF;
  z-index: 99;

}

.nav_container {
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  align-items: center;
}

ul {
  text-align: center;
  align-items: center;
}


.link {
  text-decoration: none;
  padding-right: 30px;
  color: #333333;
}

.Menu-btn {
  font-size: 30px;
  display: none;
}

.nav_container .menu {
  display: none;
}

/* Navbar */


.youtube-downloader {
  width: 100%;
  height: 100vh;
  margin-top: 100px;
}

.youtube-downloader .youtube_downloader_container {
  padding: 20px 0px;
  text-align: center;

}

.youtube-downloader .youtube_downloader_container .text {
  margin-bottom: 50px;
}

.youtube-downloader .youtube_downloader_container .text h1 {
  font-size: 25px;
  color: var(--color);
  font-weight: 600;
}

.youtube-downloader .youtube_downloader_container .text p {
  font-size: 15px;
  margin-top: -13px;
}

.search-box {
  position: relative;
  width: 50%;
  margin: 0 auto;
}

.search-box input {
  width: calc(100% - 100px);
  padding: 15px 30px;
  border-radius: 5px;
  outline: none;
  border: none;
  font-size: 14px;
  color: var(--color3);
}

.search-box button {
  position: absolute;
  top: 0;
  right: 25px;
  padding: 10px 30px;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: #39B6FE;
  color: #FFF;
  font-size: 16px;
  margin-top: 4px;
  text-align: center;
}

.no-display {
  display: none;
}

.youtube-downloader .youtube_downloader_container h1 {
  font-size: 25px;
  color: var(--color);
  font-weight: 600;
}

.youtube-downloader .how-to-download {
  padding: 30px 80px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

.youtube-downloader .how-to-download .card {
  display: flex;
  align-items: center;
}

.youtube-downloader .how-to-download .card span {
  background-color: #39B6FE !important;
  padding: 12px 15px 5px 15px;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  font-size: 28px;
  color: white;
  margin-top: 10px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
}

.youtube-downloader .how-to-download .card p {
  margin-left: 20px;
  font-size: 14px;
  /* color: var(--color2); */
}

.youtube-background-image {
  width: 100%;
  height: 1400px;
  top: -20px;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.youtube-background-image img {
  width: 100%;
  height: 1300px;
  margin-top: -30px;
}

.Faq {
  padding: 30px 80px;
}

.Faq h1 {
  color: var(--color);
  font-size: 25px;
  margin-bottom: 40px;
}

.Faq .bottom-text h4 {
  font-size: 14px;
  margin-bottom: -12px;
  color: var(--color);
}

.Faq .bottom-text p {
  font-size: 12px;
  margin-bottom: -15px;
  /* color: var(--color2); */
  margin-bottom: 10px;
}

/* Downloader */
.youtube_downloader_container .All-Data {
  margin: auto;
  margin-bottom: 50px;
}

.youtube_downloader_container .All-Data .downloader {
  width: 70%;
  height: 200px;
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 20px;
  padding: 20px;
  background-color: #39B6FE;
  margin: auto;
  box-shadow: 0 4px 4px rgba(128, 47, 47, 0.3);
  border-radius: 20px;
  text-align: left;
}

.downloader .vedio-thumbinal {
  width: 100%;
  height: 200px;
}

.downloader .vedio-thumbinal img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 0px !important;
}


.downloader .video-details .video-description h4,
.downloader .video-details .video-description span {
  font-size: 16px;
}

.downloader-buttons {
  margin-top: 10px;
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
}

.downloader-buttons #format,
.downloader-buttons #resolution,
.downloader-buttons button {
  border-radius: 10px;
  padding: 10px 20px;
}

.downloader-buttons #resolution {
  margin-top: 25px;
}

.downloader-buttons button {
  outline: none;
  border: 0.5px solid var(--color2);
}



@media only screen and (max-width: 900px) {
  .link {
    padding-right: 15px;
  }

  .nav_container .menu {
    display: none;
  }

  .youtube-downloader .youtube_downloader_container .text {
    margin-bottom: 40px;
  }

  .youtube-downloader .youtube_downloader_container .text h1 {
    font-size: 20px;
  }

  .youtube-downloader .youtube_downloader_container .text p {
    font-size: 14px;
  }

  .search-box {
    width: 80%;
  }

  .youtube-downloader .youtube_downloader_container h1 {
    font-size: 20px;
  }

  .youtube-downloader .how-to-download {
    padding: 10px 50px;
    display: grid;
    grid-template-columns: 100%;
  }

  .youtube-downloader .how-to-download .card p {
    margin-left: 15px;
  }

  .Faq {
    padding: 30px 50px;
  }

  .Faq h1 {
    font-size: 20px;
    margin-bottom: 25px;
  }

  .youtube_downloader_container .All-Data .downloader {
    width: 80%;
  }

  .downloader .vedio-thumbinal {
    width: 100%;
    height: 100%;
  }

  .downloader .vedio-thumbinal img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }

  .downloader .vedio-details .vedio-discription h4,
  .downloader .vedio-details .vedio-discription span {
    font-size: 15px;
  }

  .downloader-buttons {
    margin-top: 3px;
    flex-wrap: nowrap;
    gap: 10px;
  }

  .downloader-buttons #format,
  .downloader-buttons #resolution,
  .downloader-buttons button {
    border-radius: 10px;
    padding: 10px 15px;
  }

  .downloader-buttons #resolution {
    margin-top: 25px;
  }

  .downloader-buttons button {
    outline: none;
    border: 0.5px solid var(--color2);
  }

}

@media only screen and (max-width: 480px) {
  .nav_container {
    padding: 10px 20px;
  }

  ul {
    display: none;
  }

  .Menu-btn {
    display: block;
  }

  .nav_container .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 70px;
    right: 0;
    width: 50%;
    background-color: #FFFFFF;
    z-index: 98;
    box-shadow: 0 20px 10px rgba(128, 47, 47, 0.3);
    padding: 50px 20px;
    border-bottom-left-radius: 10px;
  }

  .link {
    margin-bottom: 40px;
  }

  .youtube-downloader .youtube_downloader_container .text {
    padding: 0px 10px;
  }

  .youtube-downloader .youtube_downloader_container .text {
    margin-bottom: 20px;
  }

  .youtube-downloader .youtube_downloader_container .text h1 {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .youtube-downloader .youtube_downloader_container .text p {
    font-size: 13px
  }

  .search-box {
    width: 100%;
  }

  .search-box input {
    width: calc(100% - 60px);
    padding: 15px 20px;
    border: 1px solid var(--color3);
  }

  .search-box button {
    right: 14px;
    padding: 10px;
    margin-top: 5px;
  }

  .youtube-downloader .youtube_downloader_container h1 {
    font-size: 18px;
  }

  .youtube-downloader .how-to-download {
    padding: 0px 15px;
    gap: 20px;
  }

  .youtube-downloader .how-to-download .card p {
    margin-left: 15px;
  }

  .Faq {
    padding: 30px 20px;
  }

  .Faq h1 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .Faq .bottom-text h4 {
    font-size: 13px;
  }

  .Faq .bottom-text p {
    font-size: 10px;
  }

  .youtube_downloader_container .All-Data .downloader {
    margin: 0 10px;
    width: 90%;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
    padding: 10px;
  }

  .downloader .vedio-thumbinal img {
    border-radius: 10px;
    margin-bottom: -30px;
  }

  .downloader .vedio-details .vedio-discription {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .downloader .vedio-details .vedio-discription h4,
  .downloader .vedio-details .vedio-discription span {
    font-size: 16px;
  }

  .downloader-buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    gap: 20px;
  }
 
  .downloader-buttons #format,
  .downloader-buttons #resolution,
  .downloader-buttons button {
    width: 100%;
    padding: 10px 20px;
  }
  .downloader-buttons #resolution {
    width: 100%;
   }


   .downloader-buttons div{
      width: 100%;
      margin-top: 0px;
   }
}


